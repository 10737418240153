export const RATINGS = {
  rating: {
    title: "Overall rating:",
    description: "How satisfied are you with this course overall?",
  },
  codeSnippetsWorking: {
    title: "Code is working:",
    description: "Do the provided code snippets function correctly?",
  },
  easilyExplained: {
    title: "Easy to understand:",
    description: "Are the instructor's explanations clear and easy to understand?",
  },
  keptUpToDate: {
    title: "Up to date:",
    description: "Is the course content up-to-date with the latest links, code versions, libraries, and technologies?",
  },
  topicCoverage: {
    title: "Comprehensive:",
    description: "Does this course thoroughly cover the topics it promises?",
  },
  organization: {
    title: "Organized:",
    description: "Is the course material well-structured and organized?",
  },
};
